<template>
  <div class="page">
    <router-view />
    <van-tabbar route active-color="#004ea3">
      <van-tabbar-item to="/index" icon="home-o">
        <span>{{ $t('common.home') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.sy.active : icon.sy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/news" icon="search"
        ><span>{{ $t('common.news') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.hq.active : icon.hq.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/trade" icon="friends-o">
        <span>{{ $t('common.trade') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.jy.active : icon.jy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/assets" icon="setting-o">
        <span>{{ $t('common.assets') }}</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.zc.active : icon.zc.inactive"
          /> </template
      ></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        sy: {
          active: require('../assets/img/sy-xz.png'),
          inactive: require('../assets/img/sy.png')
        },
        hq: {
          active: require('../assets/img/hq-xz.png'),
          inactive: require('../assets/img/hq.png')
        },
        jy: {
          active: require('../assets/img/jy-xz.png'),
          inactive: require('../assets/img/jy.png')
        },
        zc: {
          active: require('../assets/img/zc-xz.png'),
          inactive: require('../assets/img/zc.png')
        }
      }
    }
  },
  methods: {}
}
</script>
<style lang="less">
.page {
  background: #f7f7f7;
}
.van-tabbar {
  height: 3.33333rem;
  background-color: #fff;
  border-top: 0.02667rem solid #e5e5e5;
  z-index: 99;
  .van-tabbar-item img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
</style>
